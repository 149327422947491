@tailwind base;
@tailwind components;
@tailwind utilities;

/* sidebar */
/* .sidebar .list-menu .item-menu .content-menu.state-active .icon-menu,
.sidebar .list-menu .item-menu .content-menu.active-menu .icon-menu {
  color: #1e3a8a !important;
} */

/* .sidebar .list-menu .item-menu .content-menu.state-active .title-menu,
.sidebar .list-menu .item-menu .content-menu.active-menu .title-menu {
  color: #1e3a8a !important;
} */

/* .sidebar
  .list-menu
  .item-menu.menu-dropdown
  .wrapper-dropdown
  .item-dropdown
  .title-dropdown.active-menu-dropdown !important
  .title-menu-dropdown {
  color: #1e3a8a !important;
} */
/* sidebar */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.codr-image {
  height: 100vh;
}

.pagination .page-item.active {
  background: rgb(30 58 138) !important;
  color: #fff;
}

.tes-icon {
  background-color: #cce4fd;
  color: #f50000 !important;
}
