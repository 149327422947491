/* --- SCSS --- */
.sidebar-custom {
  background: rgb(1, 1, 85);

  &.is-md {
    .sidebar {
      background: blue;
    }
  }

  .sidebar {
    border-color: gray;

    .btn-extended {
      background: gray;
      color: gray;
    }

    .list-menu {
      .logo-sidebar {
        .border-sidebar {
          border: 1px solid gray;
        }
      }

      .item-menu {
        .content-menu {
          .icon-menu {
            color: white;
          }
          .icon-dropdown {
            color: white;
          }

          .title-menu {
            color: white;
          }

          &.active-menu {
            .title-menu {
              color: black;
            }

            .icon-menu {
              color: black;
            }

            .icon-dropdown {
              color: black;
            }
          }

          &:hover {
            background: black;
            .title-menu {
              color: white;
            }

            .icon-menu {
              color: white;
            }
            .icon-dropdown {
              color: white;
            }
          }
        }

        &.menu-dropdown {
          .wrapper-dropdown {
            .item-dropdown {
              .title-dropdown {
                .title-menu-dropdown {
                  color: white;
                }
                &.active-menu-dropdown {
                  .title-menu-dropdown {
                    color: black;
                  }
                }
                &:hover {
                  background: black;
                  .title-menu-dropdown {
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.sidebar-collapse {
      .item-menu {
        &.menu-dropdown {
          .wrapper-dropdown {
            &.dropdown-show {
              background: blue;
            }
          }
        }
      }
    }
  }
}


.border_top_custom{
  border-top: 1px solid #e4e4e4;
}
.custom_width_card_dashboard{
  width: 32.333333%;
}